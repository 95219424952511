import React from 'react'
import { onBoardFirstSectionContent } from '../../data/onBoard'

export const OnBoardSectionTwo = () => {
  return (
      <section className=' pl-6 lg:pl-0 lg:w-2/3 mx-auto py-14'>
          <h1 className='font-[700] text-[35px] lg:text-[45px] lg:text-center lg:leading-[60px]'>{onBoardFirstSectionContent.title.normal}<span className='text-[#985CF1]'>{onBoardFirstSectionContent.title.coloured}</span>{onBoardFirstSectionContent.title.normalContinuation}</h1>
          <p className='text-[18px] font-[400] lg:text-center mt-4 lg:mt-10'>{onBoardFirstSectionContent.paraLine}</p>
    </section>
  )
}
