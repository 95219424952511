import React from 'react'
import { onBoardSecondSectionContent } from '../../data/onBoard'

export const OnBoardSectionThree = () => {
  return (
      <section className='h-auto relative w-11/12 mx-auto mb-10'>
            <div className='absolute gradient z-[-1] h-[100%] w-[100%] rounded-[25px]'></div>
            <div className='text-center py-10'>
              <h1 className='text-[45px] font-[700]'>{onBoardSecondSectionContent.title.normal}<span className='text-[#985CF1]'>{onBoardSecondSectionContent.title.coloured}</span></h1>
              <div className='flex flex-col md:flex-row w-11/12 mx-auto pt-8'>
                  {
                      onBoardSecondSectionContent.listdata.map((data, index) => {
                          return (
                              <p key={index} className={`${data.class} text-start md:text-center px-4 py-4 font-[400] text-[18px]`}>{data.text}</p>
                          )
                      })
                  }
              </div>
            </div>
    </section>
  )
}
