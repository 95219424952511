import React from "react";
import { Layout } from "../components/Layout";
import { OnBoardPage } from "../components/OnBoard/OnBoardPage";
import "../components/index.css";


const OnBoard = () => {
    return (
        <Layout>
            <OnBoardPage />
        </Layout>
    )
}

export default OnBoard;