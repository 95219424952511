import React from 'react'
import { useModal } from '../ContactForm'
import { FooterContactBox } from '../FooterContactBox'
import { OnBoardBanner } from './OnBoardBanner'
import { OnBoardSectionFour } from './OnBoardSectionFour'
import { OnBoardSectionThree } from './OnBoardSectionThree'
import { OnBoardSectionTwo } from './OnBoardSectionTwo'

export const OnBoardPage = () => {
  const { modalAction } = useModal();
  return (
      <div>
          <OnBoardBanner />
          <OnBoardSectionTwo />
          <OnBoardSectionThree />
          <OnBoardSectionFour />
          <FooterContactBox>
            <div className='p-4 lg:p-0 lg:w-2/3'>
                    <h5 className='font-[700] text-[30px] lg:text-[38px]'>Reach out to us </h5>
                    <p className='font-[400] text-[18px] py-3'>Looking for AI powered onboarding process? Connect with us to acknowledge how to incorporate frictionless onboarding for your business.</p>
                    <button className='bg-[#985CF1] text-white py-[11px] px-[25px] rounded-[48px] mt-5' onClick={modalAction}>Contact now</button>
            </div>
          </FooterContactBox>
    </div>
  )
}
