import onBoardBannerShowImage from "../images/onBoardBannerShowImage.svg";
import payrollBanner from "../images/payrollBanner.svg";

export const onBoardBannerContent = {
    title: {
        blackColor: "Spectrawise ",
        whiteColor:"Employee Onboarding Software"
    },
    paraLine: "AI powered onboarding software makes the process seamlessly smooth and manageable",
    button: "Book a Demo",
    image:onBoardBannerShowImage
}

export const onBoardFirstSectionContent = {
    title: {
        normal: "Customized and ",
        coloured: "automated onboarding software ",
        normalContinuation:"to facilitate workforce management"
    },
    paraLine:"Using Spectrawise software, streamline the employees' onboarding process. Regulatory compliance check and seamless digital onboarding solution for all your needs. Trusted by 250 + organisations for bringing thousands of employees on board. "
}

export const onBoardSecondSectionContent = {
    title: {
        normal: "Hurdles in the ",
        coloured:"onboarding pathway"
    },
    listdata: [
        {
            text: "Discrepancies and no access to real-time data insights",
            class:"first"
        },
        {
            text: "Irregular files management leading to compliance challenges and penalties",
            class:"second"
        },
        {
            text: "Redundancy and scalability challenges amongst the disconnected systems.",
            class:"third"
        },
        {
            text: "Manual onboarding subjected to errors and wastage of time and resources.",
            class:"fourth"
        }
    ]
}

export const onBoardSectionFourContent = {
    title: {
        normal: "Enhance new hirees' ",
        coloured: "onboarding experience with the tailor-made ",
        normalContinuation:"software"
    },
    listData: [
        {
            title: "Configure",
            dimLine: "Configurable features to secure the data of organisations",
            listData: [
                "Easy data retrieval and controlled access to data",
                "Realtime identity verification",
                "Centralised and organised workflows",
                "Swift on-boarding for multiple stakeholders"
            ],
            image:payrollBanner
        },
        {
            title: "Customise",
            dimLine: "Tailored to manage your employee professional lifecycle from hire to retire.",
            listData: [
                "Configurable content.",
                "Diversity of readily available documents.",
                "Edits enabled template to meet your needs."
            ],
            image:payrollBanner
        },
        {
            title: "Automate",
            dimLine: "AI powered features to meet all the requirements and demands",
            listData: [
                "Personalized candidate portal with Al based CV parsing.",
                "Automatic enrolment on government portals.",
                "Pre-set templates for nominations and rewards."
            ],
            image:payrollBanner
        },
        {
            title: "Onboard",
            dimLine: "Perfected induction and onboarding for single hires or mass hiring.",
            listData: [
                "Personalised portals for thousands of employees.",
                "Open APIs for contactless on-boarding.",
                "Faster training for new hires.",
                "For bulk hiring, excel enabled ."
            ],
            image:payrollBanner
        }
    ]
}